export const paths = {
  home: "/home",
  faq: "/faq",
  news: "/news",
  aboutus: "/aboutus",
  pdf: "/assets/pdf",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    confirm: "/auth/confirm",
    forgetPassword: "/auth/forget-password",
    signUp: "/auth/signin-signup",
    lockScreen: "/auth/lock-screen",
    logout: "/auth/logout",
    activate: "/auth/activate",
    preactivate: "/auth/preactivate",
  },
  errors: {
    error404: "/error-404",
  },
  private: {
    editAccount: "/account/editaccount",
    messages: "/account/messages",
    creation: "/creation-page",
    offer: "/offer-page",
    profile: "/profile-page",
    review: "/review-page",
    search: "/search-page",
    editOffer: "/edit-offer-page",
    termsConditions: "/termsConditions",
    pricing: "/pricing-page",
    phonebook: "/phonebook",
  },
};

import { ReactChild } from "react";

export const ProfileResourcesEN: IProfileResources = {
  shareButton: () => (
    <>
      <i className="fa fa-share" aria-hidden="true"></i> Share user
    </>
  ),
  review: {
    averageRating: "Rating",
    supplierResponse: "Response",
    noReviews: "No reviews yet.",
  },
  title: "My Profile",
  companyTitle: "Company",
  private: "Private person",
  mobileRes: "Phone number",
  country: "Austria",
  errors: {
    notFound: "The requested profile could not been found.",
  },
  profile: {
    share: "Share",
    aboutme: "About me",
    membersince: "Member since",
    address: "Address",
    categories: "Services",
    areaofoperation: "Area of operation",
    reviewUser: "Leave a review",
    blockUser: "Block user",
    reportUser: "Report user",
    aboutus: "About us",
  },
  sendMessage: {
    button: "Write message",
    label: "Message",
    placeholder: "Message text",
    error: "Please enter a valid message.",
    name: "message",
    button2: "Send",
  },
  modal: {
    title: "Message sent.",
    text: "",
    button: "Done",
  },
  contactUs: {
    email: {
      label: "Message title",
      placeholder: "Message title",
      error: "Please enter a valid title.",
      name: "title",
      validation: "The entered E-Mail address is not valid.",
    },
    message: {
      label: "Message",
      placeholder: "Message text",
      error: "Please enter a valid message.",
      name: "message",
    },
    contact: "Email us: contact@service.co",
    button: "Send",
  },
  portfolio: "Portfolio images",
  urlCopied: "Profile URL copied to clipboard!",
};

export const ProfileResourcesDE: IProfileResources = {
  shareButton: () => (
    <>
      <i className="fa fa-share" aria-hidden="true"></i> Benutzer teilen
    </>
  ),
  urlCopied: "Profil-URL in die Zwischenablage kopiert!",
  review: {
    averageRating: "Bewertung",
    supplierResponse: "Antwort",
    noReviews: "Noch keine Bewertungen vorhanden.",
  },
  mobileRes: "Nummer",
  portfolio: "Portfolio Bilder",
  title: "Mein Profil",
  private: "Privatperson",
  companyTitle: "Unternehmen",
  country: "Österreich",
  errors: {
    notFound: "Das angeforderte Profil konnte nicht gefunden werden.",
  },
  profile: {
    share: "Teilen",
    aboutme: "Über mich",
    aboutus: "Über uns",
    membersince: "Mitglied seit",
    address: "Addresse",
    categories: "Dienstleistungen",
    areaofoperation: "Arbeitsgebiete",
    reviewUser: "Bewertung hinterlassen",
    blockUser: "Benutzer sperren",
    reportUser: "Benutzer melden",
  },
  sendMessage: {
    button: "Nachricht schreiben",
    label: "Nachricht",
    placeholder: "Nachrichtentext",
    error: "Bitte geben Sie eine gültige Nachricht ein.",
    name: "message",
    button2: "Schicken",
  },
  modal: {
    title: "Nachricht gesendet.",
    text: "",
    button: "Erledigt",
  },
  contactUs: {
    email: {
      label: "Nachrichtentitel",
      placeholder: "Nachrichtentitel",
      error: "Bitte geben Sie einen gültigen Titel ein.",
      name: "title",
      validation: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    message: {
      label: "Nachricht",
      placeholder: "Nachrichtentext",
      error: "Bitte geben Sie eine gültige Nachricht ein.",
      name: "message",
    },
    contact: "Schreiben Sie uns eine E-Mail: contact@servi.co",
    button: "Schicken",
  },
};
interface ErrorMessages {
  notFound: string;
}

interface SendMessage {
  button: string;
  label: string;
  placeholder: string;
  error: string;
  name: string;
  button2: string;
}

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ContactField {
  label: string;
  placeholder: string;
  error: string;
  name: string;
  validation?: string;
}

interface ContactUs {
  email: ContactField;
  message: ContactField;
  contact: string;
  button: string;
}

interface IProfileData {
  aboutme: string;
  aboutus: string;
  membersince: string;
  address: string;
  categories: string;
  areaofoperation: string;
  reviewUser: string;
  blockUser: string;
  reportUser: string;
  share: string;
}

export interface IProfileResources {
  title: string;
  companyTitle: string;
  errors: ErrorMessages;
  sendMessage: SendMessage;
  modal: Modal;
  contactUs: ContactUs;
  profile: IProfileData;
  country: string;
  private: string;
  mobileRes: string;
  portfolio: string;
  review: {
    averageRating: string;
    supplierResponse: string;
    noReviews: string;
  };
  urlCopied: string;
  shareButton: () => ReactChild;
}

export const SearchPageResourcesEN: ISearchPageResources = {
  title: "Search Jobs",
  messageBtn: "Open",
  filterButton: "Search",
  noOffers:
    "Unfortunately, there is no results for your search. Please review your search criteria. We will notify you as soon as a task in your services and area appears.",
  categories: {
    label: "Services",
    placeholder: "Please select a category",
  },
  postalCode: {
    label: "PostalCode",
    name: "postalCode",
    error: "Please enter a valid postal code",
    validation: "Wrong postalCode",
    zeroOption: "Please select your city first.",
    placeholder: "Please select your postal code.",
  },
  cityId: {
    label: "City",
    name: "cityId",
    error: "Please select a city",
    validation: "Wrong city",
  },
};

export const SearchPageResourcesDE: ISearchPageResources = {
  title: "Aufträge Durchsuchen",
  messageBtn: "Öffnen",
  filterButton: "Suchen",
  noOffers:
    "Leider liegen zu Ihrer Suche keine Ergebnisse vor. Bitte überprüfen Sie Ihre Suchkriterien. Wir benachrichtigen Sie, sobald eine Aufgabe in Ihren Kategorien und Ihrem Bereich erscheint.",
  categories: {
    label: "Dienstleistungen",
    placeholder: "Bitte wählen Sie eine Kategorie aus",
  },
  postalCode: {
    label: "PLZ",
    name: "postalCode",
    error: "Bitte geben Sie eine gültige Postleitzahl ein",
    validation: "Falsche Postleitzahl",
    zeroOption: "Bitte wählen Sie erst die Stadt aus.",
    placeholder: "Bitte wählen Sie Ihre Postleitzahl aus.",
  },
  cityId: {
    label: "Stadt",
    name: "cityId",
    error: "Bitte wählen Sie eine Stadt aus",
    validation: "Falsche Stadt",
  },
};

interface Categories {
  placeholder: string;
  label: string;
}

interface FieldValidationpostalCode {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  zeroOption?: string;
}

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  matchError?: string;
}

export interface ISearchPageResources {
  title: string;
  messageBtn: string;
  categories: Categories;
  postalCode: FieldValidationpostalCode;
  cityId: FieldValidation;
  noOffers: string;
  filterButton: string;
}

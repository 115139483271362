export const PhonebookResourceEN: IPhonebookResource = {
  title: "Browse Service Providers",
  messageBtn: "Open",
  filterButton: "Search",
  noOffers:
    "Unfortunately, there is no results for your search. Please review your search criteria. We will notify you as soon as a service providers in your categories and area appears.",
  categories: {
    label: "Service",
    placeholder: "Please select a service",
  },
  postalCode: {
    label: "PostalCode",
    name: "postalCode",
    error: "Please enter a valid postal code",
    validation: "Wrong postalCode",
    zeroOption: "Please select your city first.",
    placeholder: "Please select your postal code.",
  },
  cityId: {
    label: "City",
    name: "cityId",
    error: "Please select a city",
    validation: "Wrong city",
  },
  serviceCompany: {
    label: "Service provider type",
    name: "serviceCompany",
    error: "Please select your option",
    validation: "No option selected",
  },
  selection: {
    all: "All",
    company: "Company",
    private: "Private person",
  },
  companyNameRes: "Company name",
  mobileNumberRes: "Mobile number",
  categoriesRes: "Services",
  addressRes: "Address",
  aboutme: "About me",
  aboutus: "About us",
  privatePersonRes: "Private person",
  clearButton: "Clear filters",
  noStars: "No reviews.",
  rating: "Rating",
};

export const PhonebookResourceDE: IPhonebookResource = {
  noStars: "Keine Bewertungen.",
  rating: "Bewertung",
  clearButton: "Filter löschen",
  title: "Dienstleister:innen Durchsuchen",
  messageBtn: "Öffnen",
  filterButton: "Durchsuchen",
  noOffers:
    "Leider liegen zu Ihrer Suche keine Ergebnisse vor. Bitte überprüfen Sie Ihre Suchkriterien. Wir benachrichtigen Sie, sobald eine Dienstleister:in in Ihren Kategorien und Ihrem Bereich erscheint.",
  categories: {
    label: "Dienstleistung",
    placeholder: "Bitte wählen Sie eine Dienstleistung aus",
  },
  postalCode: {
    label: "PLZ",
    name: "postalCode",
    error: "Bitte geben Sie eine gültige Postleitzahl ein",
    validation: "Falsche Postleitzahl",
    zeroOption: "Bitte wählen Sie erst die Stadt aus.",
    placeholder: "Bitte wählen Sie Ihre Postleitzahl aus.",
  },
  cityId: {
    label: "Stadt",
    name: "cityId",
    error: "Bitte wählen Sie eine Stadt aus",
    validation: "Falsche Stadt",
  },
  serviceCompany: {
    label: "Dienstleister:in-Typ",
    name: "serviceCompany",
    error: "Bitte wählen Sie eine Option aus",
    validation: "Keine Option ausgewählt",
  },
  selection: {
    all: "Alle",
    company: "Firma",
    private: "Privatperson",
  },
  companyNameRes: "Unternehmen",
  mobileNumberRes: "Nummer",
  categoriesRes: "Dienstleistungen",
  addressRes: "Addresse",
  aboutme: "Über mich",
  aboutus: "Über uns",
  privatePersonRes: "Privatperson",
};

interface Categories {
  placeholder: string;
  label: string;
}

interface FieldValidationpostalCode {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  zeroOption?: string;
}

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  matchError?: string;
}

interface SelectionOptions {
  all: string;
  private: string;
  company: string;
}

export interface IPhonebookResource {
  title: string;
  messageBtn: string;
  categories: Categories;
  postalCode: FieldValidationpostalCode;
  cityId: FieldValidation;
  serviceCompany: FieldValidation;
  noOffers: string;
  filterButton: string;
  clearButton: string;
  selection: SelectionOptions;
  companyNameRes: string;
  mobileNumberRes: string;
  categoriesRes: string;
  addressRes: string;
  aboutme: string;
  aboutus: string;
  privatePersonRes: string;
  rating: string;
  noStars: string;
}

export const ReviewPageResourcesEN: IReviewPageResources = {
  title: "Rate service provider",
  button: "Submit review",
  descriptionLabel: "Review",
  descriptionPlaceholder: "Please enter your review (max. 500 signs)",
  error: "Please enter a valid review",
  starsError: "Please enter the stars rating (1-5)",
  reviewError:
    "An errror occurred during review submission. Please contact the administrator.",
};

export const ReviewPageResourcesDE: IReviewPageResources = {
  title: "Dienstleister:In bewerten",
  button: "Bewertung abgeben",
  descriptionLabel: "Bewertung",
  descriptionPlaceholder:
    "Bitte geben Sie Ihre Bewertung ein (max. 500 Zeichen)",
  error: "Bitte geben Sie eine gültige Bewertung ein",
  starsError: "Bitte geben Sie die Sternebewertung ein (1-5)",
  reviewError:
    "Ein Fehler ist beim Bewerten der Dienstleister aufgetreten. Bitte kontaktieren Sie den Administrator.",
};

export interface IReviewPageResources {
  title: string;
  button: string;
  descriptionLabel: string;
  descriptionPlaceholder: string;
  error: string;
  starsError: string;
  reviewError: string;
}

import { ButtonMain } from "../../components/Servico/ServicoStyled";
import { TextItem } from "../../components/TextCarousel/TextCarousel";

export const HomeResourcesEN: IHomeResources = {
  findService: "FIND SERVICE",
  findOffers: "FIND JOBS",
  findOffersText: "Ready for jobs? Find them here!",
  findServiceText: "Got a task? Find a service provider!",
  news: {
    title: "News",
    button: "Read more",
    news1: {
      title: "Welcome to servi.co",
      description:
        "Dear Service Providers, Welcome to servi.co, the online intermediary platform that connects service providers of all kinds with households or businesses that need your services.",
      path: "servico",
    },
    news2: {
      title:
        "Step-by-Step Guide to Registering a Sole Proprietorship (Einzelunternehmen) in Austria",
      description:
        "Starting a sole proprietorship (Einzelunternehmen) in Austria is relatively straightforward. Below, you will find a step-by-step guide, along with helpful links and tips to simplify the process.",
      path: "einzelunternehmen",
    },
    news3: {
      title:
        "The Risks and Consequences of Providing Unregistered Services in Austria",
      description:
        " Austria is known for its high standards in business practices and a strict legal framework. While it may be tempting to offer services without fulfilling all legal requirements, such actions can have significant consequences. This text highlights the legal and financial risks associated with the lack of necessary certifications, undeclared work, and tax evasion.",
      path: "risiken",
    },
  },
  title: (register) => (
    <>
      <h1 style={{ color: "white" }}>
        Looking for a service or offering one? Either way – you're in the right
        place!
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonMain
          onClick={register}
          style={{ maxWidth: "220px", height: "60px" }}
          className="btn mt-2"
        >
          REGISTER NOW
        </ButtonMain>
      </div>
    </>
  ),
  noOffersPlaceholder: "Ready, set, jobs ahead!",
  carouselItems: [
    {
      title: "SERVICE PROVIDERS",
      paragraph: "Free and no-obligation membership until 2026!",
    },
    {
      title: "SERVICE PROVIDERS",
      paragraph: "Advertise for free now and win more customers!",
    },
    {
      title: "CUSTOMERS",
      paragraph: "Find your service providers for free!",
    },
    {
      title: "CUSTOMERS",
      paragraph: "Contact the service provider your way!",
    },
  ],
  latestTitle: "Latest tasks",
  mostPopular: "Most popular tasks",
  messageBtn: "Open",
};

export const HomeResourcesDE: IHomeResources = {
  findOffers: "AUFTRÄGE SUCHEN",
  findService: "SERVICE SUCHEN",
  findOffersText: "Bereit für Aufträge? Einfach hier finden!",
  findServiceText: "Auftrag zu vergeben? Dienstleister finden!",
  news: {
    title: "Neuigkeiten",
    button: "Mehr erfahren",
    news1: {
      title: "Willkommen bei servi.co",
      description:
        "Willkommen bei servi.co, der Online-Vermittlungsplattform, die Dienstleister aller Art mit Haushalten oder Unternehmen zusammenbringt, die genau Ihre Dienstleistungen benötigen.",
      path: "servico",
    },
    news2: {
      title:
        "Schritt-für-Schritt-Anleitung zur Anmeldung eines Einzelunternehmens in Österreich",
      description:
        "Die Gründung eines Einzelunternehmens (Ein-Personen-Unternehmen) in Österreich ist vergleichsweise unkompliziert. Nachfolgend finden Sie eine Schritt-für-Schritt-Anleitung sowie hilfreiche Links und Tipps, um den Prozess zu erleichtern.",
      path: "einzelunternehmen",
    },
    news3: {
      title:
        "Die Risiken und Konsequenzen nicht registrierter Dienstleistungen in Österreich",
      description:
        "Österreich ist bekannt für hohe Standards im Geschäftsleben und ein strenges rechtliches Rahmenwerk. Auch wenn es verlockend sein mag, Dienstleistungen ohne Erfüllung aller gesetzlichen Anforderungen anzubieten, können solche Handlungen erhebliche Konsequenzen haben. Dieser Text beleuchtet die rechtlichen und finanziellen Risiken, die mit dem Fehlen notwendiger Zertifikate, Schwarzarbeit und Steuerhinterziehung einhergehen.",
      path: "risiken",
    },
  },
  title: (register) => (
    <>
      <h1 style={{ color: "white" }}>
        Suchen Sie eine Dienstleistung oder bieten Sie eine an? Ganz egal – Sie
        sind hier genau richtig!
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonMain
          onClick={register}
          style={{ maxWidth: "220px", height: "60px" }}
          className="btn mt-2"
        >
          JETZT REGISTRIEREN
        </ButtonMain>
      </div>
    </>
  ),
  noOffersPlaceholder: "Auf die Plätze, bald geht's los!",
  carouselItems: [
    {
      title: "DIENSTLEISTER:INNEN",
      paragraph:
        "Jetzt kostenlose und unverbindliche Mitgliedschaft bis 2026 sichern!",
    },
    {
      title: "DIENSTLEISTER:INNEN",
      paragraph: "Jetzt gratis werben und Kunden gewinnen!",
    },
    {
      title: "KUND:INNEN",
      paragraph: "Finden Sie Ihre:n Dienstleister:in kostenlos.",
    },
    {
      title: "KUND:INNEN",
      paragraph: "Kontaktieren Sie Ihr:e Dienstleister:in auf Ihre Weise!",
    },
  ],

  messageBtn: "Öffnen",
  latestTitle: "Aufträge",
  mostPopular: "Beliebtesten Aufträge",
};

export interface IHomeResources {
  title: (register: () => void) => React.ReactElement;
  messageBtn: string;
  latestTitle: string;
  mostPopular: string;
  noOffersPlaceholder: string;
  carouselItems: TextItem[];
  findService: string;
  findOffers: string;
  findServiceText: string;
  findOffersText: string;
  news: {
    title: string;
    button: string;
    news1: {
      title: string;
      description: string;
      path: string;
    };
    news2: {
      title: string;
      description: string;
      path: string;
    };
    news3: {
      title: string;
      description: string;
      path: string;
    };
  };
}

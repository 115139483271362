export const MenuItemsResourcesEN: IMenuItemsResources = {
  mainMenuItems: {
    public: {
      title: "Public",
      subMenuItems: {
        home: "Home",
        faq: "FAQ",
        aboutUs: "About us",
      },
    },
    private: {
      title: "Private",
      subMenuItems: {
        editAccount: "My Account",
        messages: "Communication",
        creation: "Create Job",
        offer: "Offer Page",
        profile: "My Profile",
        search: "Job Overview",
        editProfile: "Edit Task",
        chat: "Messages",
        pricing: "Pricing",
        aboutUs: "About us",
        phonebook: "Service Overview",
      },
    },
  },
};

export const MenuItemsResourcesDE: IMenuItemsResources = {
  mainMenuItems: {
    public: {
      title: "Public",
      subMenuItems: {
        home: "Home",
        faq: "FAQ",
        aboutUs: "Über uns",
      },
    },
    private: {
      title: "Private",
      subMenuItems: {
        editAccount: "Mein Konto",
        messages: "Kommunikation",
        creation: "Auftrag Erfassen",
        offer: "Offer Page",
        profile: "Mein Profil",
        search: "Auftragsübersicht",
        editProfile: "Aufträge Bearbeiten",
        chat: "Nachrichten",
        pricing: "Preise",
        aboutUs: "Über uns",
        phonebook: "Dienstleistungsübersicht",
      },
    },
  },
};

interface SubMenuItems {
  [key: string]: string;
}

interface MenuSection {
  title: string;
  subMenuItems: SubMenuItems;
}

interface MainMenuItems {
  public: MenuSection;
  private: MenuSection;
}

export interface IMenuItemsResources {
  mainMenuItems: MainMenuItems;
}

import { Navigate, Route, RouteProps } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import React from "react";
import { paths } from "./paths";

// BN Service Pages

const Home = React.lazy(() => import("../pages/home/Home"));
const FaqBN = React.lazy(() => import("../pages/faq/Faq"));
const News = React.lazy(() => import("../pages/News/News"));
const Phonebook = React.lazy(() => import("../pages/phonebook/Phonebook"));

const SearchPage = React.lazy(
  () => import("../pages/search/SearchPage/SearchPage")
);
const PricingPage = React.lazy(() => import("../pages/pricing/Pricing"));
const AccountPage = React.lazy(
  () => import("../pages/account/AccountPage/AccountPage")
);
const MessagesPage = React.lazy(
  () => import("../pages/account/MessagesPage/MessagesPage")
);

// auth
const CreationPage = React.lazy(
  () => import("../pages/creation/CreationPage/CreationPage")
);
const OfferPage = React.lazy(
  () => import("../pages/creation/OfferPage/OfferPage")
);

const EditOfferPage = React.lazy(
  () => import("../pages/creation/EditOfferPage/EditOfferPage")
);

const PDFViewer = React.lazy(() => import("../pages/pdfViewer/PDFViewer"));
const AboutUs = React.lazy(() => import("../pages/AboutUs/AboutUs"));
const ProfilePage = React.lazy(() => import("../pages/profile/Profile"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const Activate = React.lazy(() => import("../pages/auth/Activate"));
const Preactivate = React.lazy(() => import("../pages/auth/Preactivate"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/home" />,
      route: PrivateRoute,
    },
  ],
};

const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: paths.auth.login,
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: paths.auth.register,
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: paths.auth.confirm,
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: paths.auth.forgetPassword,
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: paths.auth.signUp,
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: paths.auth.lockScreen,
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: paths.auth.logout,
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: paths.auth.activate,
    name: "Activate",
    element: <Activate />,
    route: Route,
  },
  {
    path: paths.auth.preactivate,
    name: "Prectivate",
    element: <Preactivate />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: paths.errors.error404,
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <Error404Two />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <Error500Two />,
    route: Route,
  },
  {
    path: paths.pdf,
    name: "pdf",
    element: <PDFViewer />,
    route: Route,
  },
];

// BN Service - Public Routes
// TODO: Add Styled Public Routes -> Define as Public Route!!!
const customPublicRoutes = {
  path: "/",
  name: "Public",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: paths.home,
      name: "home",
      element: <Home />,
      route: PrivateRoute,
    },
    {
      path: paths.faq,
      name: "faq",
      element: <FaqBN />,
      route: PrivateRoute,
    },
    {
      path: paths.aboutus,
      name: "About Us",
      element: <AboutUs />,
      route: Route,
    },
    {
      path: paths.news,
      name: "news",
      element: <News />,
      route: PrivateRoute,
    },
  ],
};

// BN Service - Private Routes
const customPrivateRoutes = {
  path: "/account",
  name: "Account",
  route: PrivateRoute,
  // TODO: Edit Roles
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: paths.private.editAccount,
      name: "Account Page",
      element: <AccountPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.messages,
      name: "Messages Page",
      element: <MessagesPage />,
      route: PrivateRoute,
    },
  ],
};

const creationPrivateRoutes = {
  path: "/creation",
  name: "Creation",
  route: PrivateRoute,
  // TODO: Edit Roles
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: paths.private.creation,
      name: "Creation Page",
      element: <CreationPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.offer,
      name: "Offer Page",
      element: <OfferPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.profile,
      name: "Profile Page",
      element: <ProfilePage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.search,
      name: "Search Page",
      element: <SearchPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.editOffer,
      name: "Edit Offer Page",
      element: <EditOfferPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.pricing,
      name: "Pricing Page",
      element: <PricingPage />,
      route: PrivateRoute,
    },
    {
      path: paths.private.phonebook,
      name: "Phonebook Page",
      element: <Phonebook />,
      route: PrivateRoute,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  extrapagesRoutes,
  creationPrivateRoutes,
  customPrivateRoutes,
];

const styledPublicPages = flattenRoutes([customPublicRoutes]);
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  styledPublicPages,
};

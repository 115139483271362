import { EnumCountry } from "../../../redux/interfaces";
import React from "react";

export const AccountPageResourcesEN: IAccountPageResources = {
  title: "My Account",
  images: "Add photos (max. 5 pictures, max. picture size - 4 MB)",
  review: {
    averageRating: "Rating",
    supplierResponse: "Response",
    noReviews: "No reviews yet.",
    loadingReviews: "Loading reviews...",
    errorLoadingReviews: "Error loading reviews. Please try again.",
    responseFieldLabel: "Your response",
    respondButton: "Respond",
  },
  tabs: {
    personalData: "Personal Data",
    activePosts: "Active Jobs",
    archivedPosts: "Archived Jobs",
    myReviews: "My Reviews",
    userId: "User ID",
  },
  myArchive: {
    title: "Archive",
    messageBtn: "Activate",
    deleteError: "An error has occurred. Please contact the customer support.",
    deleteModal: {
      title: "",
      text: "Delete the task?",
      buttonYes: "Delete",
      buttonNo: "Back",
    },
    cityId: {
      placeholder: "Please select a city.",
      AT: {
        vienna: "Vienna",
      },
    },
    postalCode: {
      placeholder: "Please select the service areas selection.",
      labels: {
        AT: {
          vienna: {
            v1010: "1010",
            v1020: "1020",
            v1030: "1030",
            v1040: "1040",
            v1050: "1050",
            v1060: "1060",
            v1070: "1070",
            v1080: "1080",
            v1090: "1090",
            v1100: "1100",
            v1110: "1110",
            v1120: "1120",
            v1130: "1130",
            v1140: "1140",
            v1150: "1150",
            v1160: "1160",
            v1170: "1170",
            v1180: "1180",
            v1190: "1190",
            v1200: "1200",
            v1210: "1210",
            v1220: "1220",
            v1230: "1230",
          },
        },
      },
    },
  },
  myPersonalData: {
    subscriptionEndDate: "Subscription end date",
    createdAt: "User name: ",

    register: {
      modal: {
        title: "Account updated!",
        text: "",
        button: "Done",
      },
      title: "Some Title",
      registerDescription: () => (
        <React.Fragment>
          Don't have an account? Create one, it takes less than a minute.
        </React.Fragment>
      ),
      registerButton: "Update Account",
      login: {
        title: "Already have an account?",
        button: "Sign In",
      },
      myPublicId: {
        publicid: "Your public ID: ",
      },
      fields: {
        portfolio: {
          label: "Portfolio pictures",
          name: "portfolio",
          error: "Error uploading portfolio pictures.",
        },
        countryCode: {
          label: "Country code",
          name: "countryCode",
          error: "Please enter a valid country code.",
          validation: "Please enter a valid country code.",
        },
        description: {
          label: "About you",
          placeholder: "Please introduce yourself",
          name: "description",
          error: "Please introduce yourself.",
        },
        serviceCompany: {
          label: "Are you a company?",
          name: "serviceCompany",
          error: "Please choose a valid answer.",
          validation: "",
        },
        serviceCompanyName: {
          label: "Company Name",
          name: "serviceCompanyName",
          error: "“If you’re a company please insert the company name.",
          validation: "Wrong company name.",
        },
        termsConditions: {
          label: "I accept Terms and Conditions",
        },
        type: {
          label: "Account Type",
          name: "type",
          error: "Please select your account type.",
          validation: "Wrong account type.",
          client: "CLIENT",
          supplier: "SERVICE PROVIDER",
        },
        email: {
          label: "E-Mail",
          name: "email",
          error: "Please enter a valid E-Mail.",
          validation: "Wrong E-Mail.",
        },
        password: {
          label: "Password",
          name: "password",
          error: "Please enter a valid password.",
          validation: "Wrong password.",
        },
        name: {
          label: "Name",
          name: "name",
          error: "Please enter a valid name.",
          validation: "Wrong name.",
        },
        address: {
          zeroOption: "Please select your city first.",
          postalCode: {
            label: "Postal code",
            name: "postalCode",
            error: "Please enter a valid postal code",
            validation: "Wrong postal code.",
          },
          country: {
            label: "Country",
            name: "country",
            error: "Please select a country.",
            validation: "Wrong country.",
            countries: {
              AUSTRIA: "Austria",
            },
          },
          cityId: {
            label: "City",
            name: "cityId",
            error: "Please select a city",
            validation: "Wrong city",
            cities: {
              AUSTRIA: {
                VIENNA: "Vienna",
              },
            },
          },
          street: {
            label: "Street",
            name: "street",
            error: "Please enter a valid street.",
            validation: "Wrong street.",
          },
          elevator: {
            label: "Elevator",
            name: "elevator",
            error: "Please select a elevator option.",
            validation: "Wrong elevator.",
          },
          stairs: {
            label: "Entrance",
            name: "stairs",
            error: "Please enter valid entrance.",
            validation: "Wrong entrance.",
          },
          floor: {
            label: "Floor",
            name: "floor",
            error: "Please enter a valid floor number.",
            validation: "Wrong floor number.",
          },
          apartmentNumber: {
            label: "Apartment Number",
            name: "apartmentNumber",
            error: "Please enter a valid apartment number.",
            validation: "Wrong apartment number.",
          },
        },
        phoneNumber: {
          label: "Phone Number",
          name: "phoneNumber",
          error: "Please enter a valid phone number.",
          validation: "Wrong phone number.",
        },
        profilePicture: {
          label: "Profile Picture",
          name: "profilePicture",
          error: "Please upload your profile picture",
          validation: "Wrong profile picture.",
          description: () => (
            <>
              <h4>Please upload your profile picture</h4>
              <span className="text-muted font-13">Only images, max 4MB</span>
            </>
          ),
          pictureSizeError: "The photo you're trying to upload is too large.",
        },
        birthDate: {
          label: "Birth Date",
          name: "birthDate",
          error:
            "Please enter a valid birth date. The user must be at least 18 years old.",
          validation: "Wrong birth date.",
        },
        paypal: {
          label: "PayPal",
          name: "paypal",
          error: "Please enter a valid PayPal.",
          validation: "Wrong PayPal.",
        },
        iban: {
          label: "IBAN",
          name: "iban",
          error: "Please enter a valid IBAN.",
          validation: "Wrong IBAN.",
        },
        categories: {
          label: "Services",
          name: "categories",
          error: "Please select your services.",
          validation: "Wrong services.",
          options: [
            {
              label: "Baustelle brudi",
              value: "Baustelle brudi",
            },
            {
              label: "Baustelle 2 brudi",
              value: "Baustelle 2 brudi",
            },
          ],
        },
        ortSelektiert: {
          label: "Service areas selection",
          name: "ortSelektiert",
          error: "Please select your service areas selection.",
          validation: "Wrong service areas selection.",
          options: [
            {
              label: "1010",
              value: "1010",
            },
            {
              label: "1020",
              value: "1020",
            },
            {
              label: "1030",
              value: "1030",
            },
            {
              label: "1040",
              value: "1040",
            },

            {
              label: "1050",
              value: "1050",
            },
            {
              label: "1060",
              value: "1060",
            },
            {
              label: "1070",
              value: "1070",
            },
          ],
        },
      },
    },
  },

  myPosts: {
    activePostPlaceholder: "You don’t have active tasks at the moment.",
    archivedPostPlaceholder: "You don’t have archived tasks at the moment.",
    createOfferButton: "CREATE NOW",
    title: "SearchPageResourcesEN Page",
    archivedDate: "The task will be stored in the archive at: ",
    messageBtn: "Edit Task",
    address: "Address",
    date: "Date",
    description: "Description",
    deleteError:
      "An Error has occured during deleting the task. Please contact the customer support.",
    deleteModal: {
      title: "",
      text: "Delete the task?",
      buttonYes: "Delete",
      buttonNo: "Back",
    },
    categories: {
      placeholder: "Please select your category(ies).",
      cleaning: {
        label: "Cleaning",
        values: {
          houseCleaning: "House Cleaning",
          officeCleaning: "Office Cleaning",
        },
      },
      houseWorks: {
        label: "House Works",
        values: {
          plumber: "Plumber",
          electricity: "Electricity",
        },
      },
    },
    cityId: {
      placeholder: "Please select a city.",
      AT: {
        vienna: "Vienna",
      },
    },
    postalCode: {
      placeholder: "Please select the postal code.",
      labels: {
        AT: {
          vienna: {
            v1010: "1010",
            v1020: "1020",
            v1030: "1030",
            v1040: "1040",
            v1050: "1050",
            v1060: "1060",
            v1070: "1070",
            v1080: "1080",
            v1090: "1090",
            v1100: "1100",
            v1110: "1110",
            v1120: "1120",
            v1130: "1130",
            v1140: "1140",
            v1150: "1150",
            v1160: "1160",
            v1170: "1170",
            v1180: "1180",
            v1190: "1190",
            v1200: "1200",
            v1210: "1210",
            v1220: "1220",
            v1230: "1230",
          },
        },
      },
    },
  },
};

export const AccountPageResourcesDE: IAccountPageResources = {
  title: "Mein Konto",
  images: "Fotos hinzufügen (max. 5 Bilder, maximale Bildgröße - 4 MB)",
  review: {
    averageRating: "Bewertung",
    supplierResponse: "Antwort",
    noReviews: "Noch keine Bewertungen vorhanden.",
    loadingReviews: "Bewertungen werden geladen...",
    errorLoadingReviews:
      "Fehler beim Laden der Bewertungen. Bitte versuchen Sie es erneut.",
    responseFieldLabel: "Ihre Antwort",
    respondButton: "Antworten",
  },
  tabs: {
    personalData: "Persönliche Daten",
    activePosts: "Aktive Aufträge",
    archivedPosts: "Archivierte Aufträge",
    myReviews: "Meine Bewertungen",
    userId: "Benutzer-ID",
  },
  myArchive: {
    title: "Archiv",
    messageBtn: "Aktivieren",
    deleteError:
      "Es ist ein Fehler aufgetreten. Kontaktieren Sie bitte den Administrator.",
    deleteModal: {
      title: "",
      text: "Den Auftrag löschen?",
      buttonYes: "Löschen",
      buttonNo: "Zurück",
    },
    cityId: {
      placeholder: "Bitte wählen Sie eine Stadt aus.",
      AT: {
        vienna: "Vienna",
      },
    },
    postalCode: {
      placeholder: "Bitte wählen Sie die PLZ aus.",
      labels: {
        AT: {
          vienna: {
            v1010: "1010",
            v1020: "1020",
            v1030: "1030",
            v1040: "1040",
            v1050: "1050",
            v1060: "1060",
            v1070: "1070",
            v1080: "1080",
            v1090: "1090",
            v1100: "1100",
            v1110: "1110",
            v1120: "1120",
            v1130: "1130",
            v1140: "1140",
            v1150: "1150",
            v1160: "1160",
            v1170: "1170",
            v1180: "1180",
            v1190: "1190",
            v1200: "1200",
            v1210: "1210",
            v1220: "1220",
            v1230: "1230",
          },
        },
      },
    },
  },
  myPersonalData: {
    subscriptionEndDate: "Enddatum des Abonnements",
    createdAt: "Benutzername: ",
    register: {
      modal: {
        title: "Das Konto wurde aktualisiert.",
        text: "",
        button: "Erledigt",
      },
      title: "Some Title",
      registerDescription: () => (
        <React.Fragment>
          Sie haben noch kein Konto? Erstellen Sie eins, es dauert weniger als
          eine Minute.
        </React.Fragment>
      ),
      registerButton: "Konto aktualisieren",
      login: {
        title: "Sie haben bereits ein Konto?",
        button: "Anmelden",
      },
      myPublicId: {
        publicid: "Your public ID: ",
      },
      fields: {
        countryCode: {
          label: "Landcode",
          name: "countryCode",
          error: "Bitte geben Sie eine gültige Landcode ein.",
          validation: "Bitte geben Sie eine gültige Landcode ein.",
        },
        description: {
          label: "Über Sie",
          placeholder: "Stellen Sie sich bitte vor.",
          name: "description",
          error: "Stellen Sie sich bitte vor.",
        },
        serviceCompany: {
          label: "Sind Sie ein Unternehmen?",
          name: "serviceCompany",
          error: "Bitte wählen Sie eine gültige Antwort.",
          validation: "",
        },
        serviceCompanyName: {
          label: "Firmenname",
          name: "serviceCompanyName",
          error:
            "Wenn Sie ein Unternehmen sind, geben Sie bitte den Firmennamen ein.",
          validation: "Falscher Firmenname.",
        },
        termsConditions: {
          label: "Ich akzeptiere die AGB",
        },
        type: {
          label: "Kontotyp",
          name: "type",
          error: "Bitte wählen Sie Ihren Kontotyp aus.",
          validation: "Falscher Kontotyp.",
          client: "KUND:IN",
          supplier: "DIENSTLEISTER:IN",
        },
        email: {
          label: "E-Mail",
          name: "email",
          error: "Bitte geben Sie eine gültige Email-Adresse ein.",
          validation: "Falsche E-Mail.",
        },
        password: {
          label: "Passwort",
          name: "password",
          error: "Bitte geben Sie ein gültiges Passwort ein.",
          validation: "Falsches Passwort.",
        },
        name: {
          label: "Name",
          name: "name",
          error: "Bitte geben Sie einen gültigen Namen ein.",
          validation: "Falscher Name.",
        },
        address: {
          zeroOption: "Bitte wählen Sie erst die Stadt aus.",
          postalCode: {
            label: "PLZ",
            name: "postalCode",
            error: "Bitte geben Sie eine gültige PLZ ein.",
            validation: "Falsche PLZ.",
          },
          country: {
            label: "Land",
            name: "country",
            error: "Bitte wählen Sie ein Land.",
            validation: "Falsches Land.",
            countries: {
              AUSTRIA: "Austria",
            },
          },
          cityId: {
            label: "Stadt",
            name: "cityId",
            error: "Bitte wählen Sie eine Stadt aus.",
            validation: "Falsche Stadt.",
            cities: {
              AUSTRIA: {
                VIENNA: "Vienna",
              },
            },
          },
          street: {
            label: "Straße",
            name: "street",
            error: "Bitte geben Sie eine gültige Straße ein.",
            validation: "Falsche Straße.",
          },
          elevator: {
            label: "Aufzug",
            name: "elevator",
            error: "Bitte wählen Sie eine Aufzugsoption aus.",
            validation: "Falscher Aufzug.",
          },
          stairs: {
            label: "Stiege",
            name: "stairs",
            error: "Bitte geben Sie eine gültige Stiege ein.",
            validation: "Falsche Stiege.",
          },
          floor: {
            label: "Stock",
            name: "floor",
            error: "Bitte geben Sie einen gültigen Stock ein.",
            validation: "Falscher Stock.",
          },
          apartmentNumber: {
            label: "Türnummer",
            name: "apartmentNumber",
            error: "Bitte geben Sie eine gültige Türnummer ein.",
            validation: "Falsche Türnummer.",
          },
        },
        phoneNumber: {
          label: "Telefonnummer",
          name: "phoneNumber",
          error: "Bitte geben Sie eine gültige Telefonnummer ein.",
          validation: "Falsche Telefonnummer.",
        },
        portfolio: {
          label: "Portfolio-Bilder",
          name: "portfolio",
          error: "Fehler beim hochladen der Portfolio Bilder",
        },
        profilePicture: {
          label: "Profilbild",
          name: "profilePicture",
          error: "Bitte geben Sie ein gültiges Profilbild ein.",
          validation: "Falsches Profilbild.",
          description: () => (
            <>
              <h4>Bitte laden Sie ein gültiges Profilbild hoch</h4>
              <span className="text-muted font-13">Nur Bilder, max 4MB</span>
            </>
          ),
          pictureSizeError:
            "Das Foto, das Sie hochladen versuchten, ist zu groß.",
        },
        birthDate: {
          label: "Geburtsdatum",
          name: "birthDate",
          error:
            "Bitte geben Sie ein gültiges Geburtsdatum ein. Der Benutzer muss mindestens 18 Jahre alt sein.",
          validation: "Falsches Geburtsdatum.",
        },
        paypal: {
          label: "PayPal",
          name: "paypal",
          error: "Bitte geben Sie ein gültiges PayPal-Konto ein.",
          validation: "Falsches PayPal.",
        },
        iban: {
          label: "IBAN",
          name: "iban",
          error: "Bitte geben Sie eine gültige IBAN ein.",
          validation: "Falsche IBAN.",
        },
        categories: {
          label: "Dienstleistungen",
          name: "categories",
          error: "Bitte wählen Sie Ihre Dienstleistung aus.",
          validation: "Falsche Kategorien.",
          options: [
            {
              label: "Baustelle brudi",
              value: "Baustelle brudi",
            },
            {
              label: "Baustelle 2 brudi",
              value: "Baustelle 2 brudi",
            },
          ],
        },
        ortSelektiert: {
          label: "Auswahl der Arbeitsgebiete",
          name: "ortSelektiert",
          error: "Bitte wählen Sie Ihre Arbeitsgebiete aus.",
          validation: "Falsche PLZ.",
          options: [
            { label: "1010", value: "1010" },
            { label: "1020", value: "1020" },
            { label: "1030", value: "1030" },
            { label: "1040", value: "1040" },
            { label: "1050", value: "1050" },
            { label: "1060", value: "1060" },
            { label: "1070", value: "1070" },
            { label: "1080", value: "1080" },
            { label: "1090", value: "1090" },
            { label: "1100", value: "1100" },
            { label: "1110", value: "1110" },
            { label: "1120", value: "1120" },
            { label: "1130", value: "1130" },
            { label: "1140", value: "1140" },
            { label: "1150", value: "1150" },
            { label: "1160", value: "1160" },
            { label: "1170", value: "1170" },
            { label: "1180", value: "1180" },
            { label: "1190", value: "1190" },
            { label: "1200", value: "1200" },
            { label: "1210", value: "1210" },
            { label: "1220", value: "1220" },
            { label: "1230", value: "1230" },
          ],
        },
      },
    },
  },
  myPosts: {
    activePostPlaceholder: "Sie haben momentan keine aktiven Aufträge.",
    archivedPostPlaceholder: "Sie haben momentan keine archivierten Aufträge.",
    createOfferButton: "JETZT ERSTELLEN",
    address: "Addresse",
    archivedDate: "Der Auftrag wird im Archiv gespeichert unter: ",
    date: "Datum",
    description: "Beschreibung",
    title: "SearchPageResourcesDE Page",
    messageBtn: "Auftrag Bearbeiten",
    deleteError:
      "Es ist ein Fehler aufgetreten. Kontaktieren Sie bitte den Administrator.",
    deleteModal: {
      title: "",
      text: "Den Auftrag löschen?",
      buttonYes: "Löschen",
      buttonNo: "Zurück",
    },
    categories: {
      placeholder: "Bitte wählen Sie Ihre Kategorie(n).",
      cleaning: {
        label: "Cleaning",
        values: {
          houseCleaning: "House Cleaning",
          officeCleaning: "Office Cleaning",
        },
      },
      houseWorks: {
        label: "House Works",
        values: {
          plumber: "Plumber",
          electricity: "Electricity",
        },
      },
    },
    cityId: {
      placeholder: "Bitte wählen Sie eine Stadt aus.",
      AT: {
        vienna: "Vienna",
      },
    },
    postalCode: {
      placeholder: "Bitte wählen Sie die PLZ aus.",
      labels: {
        AT: {
          vienna: {
            v1010: "1010",
            v1020: "1020",
            v1030: "1030",
            v1040: "1040",
            v1050: "1050",
            v1060: "1060",
            v1070: "1070",
            v1080: "1080",
            v1090: "1090",
            v1100: "1100",
            v1110: "1110",
            v1120: "1120",
            v1130: "1130",
            v1140: "1140",
            v1150: "1150",
            v1160: "1160",
            v1170: "1170",
            v1180: "1180",
            v1190: "1190",
            v1200: "1200",
            v1210: "1210",
            v1220: "1220",
            v1230: "1230",
          },
        },
      },
    },
  },
};

interface RegisterDescriptionProps {
  children?: React.ReactNode;
}

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
}

interface FieldWithOptions extends FieldValidation {
  options: { label: string; value: string }[];
}

interface AddressField {
  zeroOption: string;
  postalCode: FieldValidation;
  country: FieldValidation & { countries: Record<EnumCountry, string> };
  cityId: FieldValidation & {
    cities: Record<EnumCountry, Record<string, string>>;
  };
  street: FieldValidation;
  elevator: FieldValidation;
  stairs: FieldValidation;
  floor: FieldValidation;
  apartmentNumber: FieldValidation;
}

interface AccountTypeField extends FieldValidation {
  client: string;
  supplier: string;
}

interface CategoriesField {
  placeholder: string;
  cleaning: {
    label: string;
    values: {
      houseCleaning: string;
      officeCleaning: string;
    };
  };
  houseWorks: {
    label: string;
    values: {
      plumber: string;
      electricity: string;
    };
  };
}

interface DeleteModal {
  title: string;
  text: string;
  buttonYes: string;
  buttonNo: string;
}

interface MyPosts {
  title: string;
  activePostPlaceholder: string;
  createOfferButton: string;
  archivedPostPlaceholder: string;
  messageBtn: string;
  categories: CategoriesField;
  address: string;
  date: string;
  description: string;
  archivedDate: string;
  cityId: {
    placeholder: string;
    AT: {
      vienna: string;
    };
  };
  postalCode: {
    placeholder: string;
    labels: {
      AT: CitiesRecords;
    };
  };
  deleteModal: DeleteModal;
  deleteError: string;
}

interface MyArchive {
  title: string;
  messageBtn: string;
  cityId: {
    placeholder: string;
    AT: {
      vienna: string;
    };
  };
  postalCode: {
    placeholder: string;
    labels: {
      AT: CitiesRecords;
    };
  };
  deleteModal: DeleteModal;
  deleteError: string;
}

interface CitiesRecords {
  vienna: {
    v1010: string;
    v1020: string;
    v1030: string;
    v1040: string;
    v1050: string;
    v1060: string;
    v1070: string;
    v1080: string;
    v1090: string;
    v1100: string;
    v1110: string;
    v1120: string;
    v1130: string;
    v1140: string;
    v1150: string;
    v1160: string;
    v1170: string;
    v1180: string;
    v1190: string;
    v1200: string;
    v1210: string;
    v1220: string;
    v1230: string;
  };
}
interface Modal {
  title: string;
  text: string;
  button: string;
}

interface MyPersonalData {
  createdAt: string;
  subscriptionEndDate: string;
  register: {
    title: string;
    registerDescription: React.ComponentType<RegisterDescriptionProps>;
    registerButton: string;
    login: {
      title: string;
      button: string;
    };
    myPublicId: {
      publicid: string;
    };
    fields: {
      serviceCompany: FieldValidation;
      serviceCompanyName: FieldValidation;
      termsConditions: {
        label: string;
      };
      description: FieldValidation;
      type: AccountTypeField;
      email: FieldValidation;
      password: FieldValidation;
      name: FieldValidation;
      address: AddressField;
      phoneNumber: FieldValidation;
      profilePicture: FieldValidation & {
        description: () => void;
        pictureSizeError: string;
      };
      birthDate: FieldValidation;
      portfolio: FieldValidation;
      paypal: FieldValidation;
      iban: FieldValidation;
      categories: FieldWithOptions;
      ortSelektiert: FieldWithOptions;
      countryCode: FieldValidation;
    };
    modal: Modal;
  };
}

export interface IAccountPageResources {
  title: string;
  myPersonalData: MyPersonalData;
  myPosts: MyPosts;
  myArchive: MyArchive;
  images: string;
  tabs: {
    personalData: string;
    activePosts: string;
    archivedPosts: string;
    myReviews: string;
    userId: string;
  };
  review: {
    averageRating: string;
    supplierResponse: string;
    noReviews: string;
    loadingReviews: string;
    errorLoadingReviews: string;
    responseFieldLabel: string;
    respondButton: string;
  };
}
